import logo from './logo.svg';
import './App.css';
// import Graph from './Graph'
import NewGraph from './NewGraph';

function App() {
  return (
    <div className="App">
      <NewGraph />
    </div>
  );
}

export default App;
